<template>
  <div class="index">
    <div class="title">
      <div class="titleFlex">
        荐神APP
        <img
          class="title-img"
          src="../assets/icon-unfold@2x.png"
          @click="imgClick"
        />
      </div>
    </div>
    <div class="text1">
      <img class="text1-img" src="../assets/text-荐神@2x.png" />
      <div class="text1-text">多年经验的职场精英</div>
      <div class="text1-text">精准评估与指导，全程守护求职进程。</div>
    </div>
    <!-- <div class="phone">
      <div class="new-phoneImg">
        <img src="../assets/new/荐神.gif" alt="" class="new-phoneImgGif" />
      </div>
      <a!-- <img class="phone-img1" src="../assets/iphone@2x.png" /> --a>
      <img class="phone-img2" src="../assets/decoration-finger@2x.png" />
    </div> -->
    <div class="phone-new1">
      <img class="img-new1" src="../assets/new/荐神-手机.gif" alt="">
    </div>
    <div class="down">
      <div class="down-text">向下滑动</div>
      <img class="down-img" src="../assets/vector-scroll@2x.png" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    imgClick() {
      // this.$router.push({
      //   name: 'abooutUs',
      // })
      this.$emit('updatea', true);
    },
  },
}
</script>
<style scoped>
.titleFlex {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  color: #06bf6d;
  font-size: 17px;
}
.index {
  width: 100%;
  height: 100%;
  color: #000;
}

.title {
  text-align: right;
  margin: 14px 18px 24px 0;
}
.title-img {
  width: 45px;
}
.text1 {
  text-align: center;
}
.text1-img {
  width: 66px;
  height: 33px;
  margin-bottom: 15px;
}
.text1-text {
  margin-bottom: 2px;
  padding: 0 114px;
  text-align: center;
  color: #78879c;
  font-size: 12px;
}

.phone-new1{
  width: 100%;
}
.img-new1 {
    max-width: 100%;
  }
.new-phoneImg {
  /* height: 378x; */
  padding-bottom: 36%;
  position: relative;
  background-image: url('../assets/iphone@2x.png');
  background-size: cover;
}
.new-phoneImg .new-phoneImgGif {
  margin-top: 20%;
  margin-left: -18%;
  width: 38.5%;
  /* height: 309px; */
  /* position: relative; */
}
.phone {
  /* height: 378x; */
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}
.phone-img1 {
  width: 100%;
}

.phone-img2 {
  width: 53px;
  height: 53px;
  position: absolute;
  bottom: 29%;
  left: 57%;
}

.down {
  text-align: center;
  position: absolute;
  bottom: 7%;
  width: 100%;
}

.down-text {
  color: #808ea2;
  margin-bottom: 7px;
}
.down-img {
  height: 39px;
  /* margin-bottom: 36px; */
}
</style>
