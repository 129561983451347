<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
}

img {
  border: none;
  vertical-align: middle;
}
html,
body {
  width: 100%;
  height: 100%;
}
</style>
