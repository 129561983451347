<template>
	<div>
		<div class="title">
			<div class="titleFlex">
			<div >荐神APP</div>
			<img class="title-img" src="../assets/icon-unfold@2x.png"  @click="showMask = true">
		</div>
		</div>
		<div class="text1">
			<img class="text1-img" src="../assets/text-做一个有温度@2x.png">
		</div>
		<!-- <div class="phone">
			<div class="new-phoneImg">
				<img src="../assets/new/云录制面试.gif" alt="" class="new-phoneImgGif" />
			</div>
			<img class="phone-img2" src="../assets/decoration-phone@2x.png">
			<img class="phone-img3" src="../assets/decoration-boy@2x.png">
			<img class="phone-img4" src="../assets/decoration-ok@2x.png">
		</div> -->
		<div class="phone-new1">
			<img class="img-new1" src="../assets/new/荐神-关于我们-手机.gif" alt="">
		</div>
		<div class="aboutUs">
			关于我们
			<img class="aboutUs-img" src="../assets/decoration-green-strap@2x.png">
		</div>
		<div class="aboutText">
			“荐神”是一个致力于“极速成功入职”为目标的、按效果付费的求职招聘平台。它开放所有人才库，全新的职位曝光算法，多功能精准搜索，并以“云录制面试”方式，做到            
            一次沟通多次精准推荐，极大限度降低企业、能人的成本。
		</div>

		<div class="role">
			角色
			<img class="role-img" src="../assets/decoration-green-strap@2x.png">
		</div>
		<div class="roleItem">
			<div class="roleItem-item">
				<img class="roleItem-img" src="../assets/icon-荐神@2x.png">
				<div class="roleItem-text">深耕多年的职场精英，精准评估与指导，全程守护求职、招聘进程</div>
			</div>
			<div class="roleItem-item">
				<img class="roleItem-img" src="../assets/icon-企业@2x.png">
				<div class="roleItem-text">随时、按需购买，按招聘效果付费。</div>
			</div>
			<div class="roleItem-item">
				<img class="roleItem-img" src="../assets/icon-能人@2x.png">
				<div class="roleItem-text">即求职者。多位荐神助力寻找工作机会，7*24小时精准推荐岗位，不错过任何一个工作的机会。</div>
			</div>
		</div>


		<div class="function">
			主要功能
			<img class="function-img" src="../assets/decoration-green-strap@2x.png">
		</div>
		<div class="functionItem">
			<div class="functionItem-item">
				<img class="functionItem-img" src="../assets/icon-云录制面试@2x.png">
				<div class="functionItem-text">面试经授权后会被录制，作为“一次面试，多次精准推荐”的依据</div>
			</div>
			<div class="functionItem-item">
				<img class="functionItem-img" src="../assets/icon-荐神报告@2x.png">
				<div class="functionItem-text">云录制面试后的荐神记录，对能人求职意向、沟通、专业等能力的评估报告</div>
			</div>
			<div class="functionItem-item">
				<img class="functionItem-img" src="../assets/icon-推荐ta@2x.png">
				<div class="functionItem-text">依据荐神报告，撰写推荐信，批量推荐多个精准职位，加速推荐效率</div>
			</div>
			<div class="functionItem-item">
				<img class="functionItem-img" src="../assets/icon-结算@2x.png">
				<div class="functionItem-text">面试、入职两个节点，荐神分别获得大量推荐奖励</div>
			</div>
			<div class="functionItem-item">
				<img class="functionItem-img" src="../assets/icon-消息@2x.png">
				<div class="functionItem-text">和职场前辈深聊 深深地聊</div>
			</div>
		</div>


		<div class="contactUs">
			联系我们
			<img class="contactUs-img" src="../assets/decoration-green-strap@2x.png">
		</div>
		<div class="QR">
			<img class="QR-img" src="../assets/QR@2x.png">
		</div>
		<div class="tips">
			长按识别
		</div>
	</div>
	<div class="footer1">
      <div class="fotterCountent">
        <div class="fotterTextStyle" style="margin-top: 24px">
          荐神（上海）科技有限公司
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
          "
        >
          <span class="fotterTextStyle" @click="handelOpen('wh')"  style="text-decoration: underline;">
            沪ICP备2023006285号-1
          </span>
          <!-- <img src="../assets/icon-公安.png" alt="" style="margin: 0 8px" /> -->
          <!-- <span class="fotterTextStyle" @click="handelOpen(false)"> -->
            <!--沪公网安备  号-->
          <!-- </span> -->
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            src="../assets/icon-email.png"
            alt=""
            style="margin-right: 8px"
          />
          <span class="fotterTextStyle"> 邮箱地址：gavin@ituiu.com </span>
        </div>
      </div>
    </div>
	<div class="loading">
		<div class="mask" v-if="showMask">
			<div class="content">
				<p @click="goToRoute('/')">首 页&nbsp;&nbsp; 〉</p>
				<p @click="goToRoute('/abooutUs')">关于我们&nbsp;&nbsp; 〉</p>
			</div>
			<div class="title">
				<div class="titleFlex">
					<div  @click="() => $router.push('/')">荐神APP</div>
					<div  @click="showMask = false">X</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
        showMask : false,
    }
  },
  methods: {
  goToRoute(routeName) {
	let u = this.$route.name;
	if(u==routeName){
		this.showMask = false;
	}else{
		this.$router.push(routeName);
	}
  },
  handelOpen(type) {
      if (type) {
        window.open('https://beian.miit.gov.cn/')
      } else {
        window.open('https://beian.mps.gov.cn/#/')
      }
    },
  },
}
</script>
<style scoped>
.mask {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
}
.content {
    position: absolute;
    top: 50%;
    left: 0;
    color: white;
    text-align: center;
    line-height: 66px;
    width: 100%;
}
.titleFlex {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  color: #06bf6d;
  font-size: 17px;
}
.title{
	text-align: right;
	padding: 14px 18px 31px 0;
}

.titleFlex {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  color: #06bf6d;
  font-size: 17px;
}
.title{
	text-align: right;
	padding: 14px 18px 31px 0;
}
.title-img{
	width: 45px
}
.text1{
	text-align: center;
	margin-bottom: 32px;
}
.text1-img{
	width: 257px;
	height: 58px;
}
.phone{
	height: 377px;
	margin-bottom: 120px;
	text-align: center;
	position: relative;
}
.phone-new1{
  width: 100%;
}

.img-new1 {
    max-width: 100%;
  }
.new-phoneImg {
  /* height: 378x; */
  padding-bottom: 37%;
  position: relative;
  background-image: url('../assets/iphone@2x.png');
  background-size: cover;
  background-position: 30px 0;
}
.new-phoneImg .new-phoneImgGif {
  margin-top: 20%;
  margin-left: -2.5%;
  width: 150px;
  /* height: 309px; */
  /* position: relative; */
}
.phone-img1{
	width: 188px;
	height: 377px;
}
.phone-img2{
	width: 46px;
	height: 46px;
	position: absolute;
	top: 16%;
	left: 14%;
}
.phone-img3{
	width: 140px;
	height: 140px;
	position: absolute;
	top: 67%;
	right: 1%;
}
.phone-img4{
	width: 64px;
	height: 64px;
	position: absolute;
	top: 100%;
	left: 25%;
}

.aboutUs{
	position: relative;
	margin-bottom: 7px;
	height: 30px;
	font-size: 21px;
	text-align: center;
	color: #0E1A2D;
}
.aboutUs-img{
	width: 46px;
	height: 9px;
	font-weight: 900;
	position: absolute;
	left: 135px;
	top: 50%;
	transform: translateY(-50%);
}
.aboutText{
	padding:  25px;
	color: #78879C;
	font-size: 14px;
	text-align: center;
}

.role{
	margin-bottom: 21px;
	position: relative;
	height: 30px;
	font-size: 21px;
	text-align: center;
	color: #0E1A2D;
}
.role-img{
	width: 46px;
	height: 9px;
	font-weight: 900;
	position: absolute;
	left: 156px;
	top: 50%;
	transform: translateY(-50%);
}

.roleItem{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 56px;
}
.roleItem-item{
	width: calc(50% - 36px);
	text-align: center;
	height: 173px;
	padding: 0 18px;
}
.roleItem-img{
	width: 70px;
	height: 70px;
	margin-bottom: 7px;
}
.roleItem-text{
	font-size: 14px;
	color: #78879C;
}
.roleItem-item:last-child{
	width:calc(100% - 30px);
	text-align: center;
	height: 116px;
	padding: 0 15px;
}
.function{
	margin-bottom: 21px;
	position: relative;
	height: 30px;
	font-size: 21px;
	text-align: center;
	color: #0E1A2D;
}
.function-img{
	width: 46px;
	height: 9px;
	font-weight: 900;
	position: absolute;
	left: 135px;
	top: 50%;
	transform: translateY(-50%);
}
.functionItem{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 56px;
}
.functionItem-item{
	width: calc(50% - 36px);
	text-align: center;
	height: 173px;
	padding: 0 18px;
}
.functionItem-img{
	width: 70px;
	height: 70px;
	margin-bottom: 7px;
}
.functionItem-text{
	font-size: 14px;
	color: #78879C;
}
.functionItem-item:last-child{
	width:100%;
	text-align: center;
	height: 116px;
	padding: 0 135px;
}

.contactUs{
	margin-bottom: 21px;
	position: relative;
	height: 30px;
	font-size: 21px;
	text-align: center;
	color: #0E1A2D;
}
.contactUs-img{
	width: 46px;
	height: 9px;
	font-weight: 900;
	position: absolute;
	left: 137px;
	top: 50%;
	transform: translateY(-50%);
}
.QR{
	text-align: center;
	margin-bottom: 10px;
}
.QR-img{
	width: 255px;
	height: 259px;
}
.tips{
	text-align: center;
	color: #78879C;
	font-size: 12px;
	margin-bottom: 40px;
}
.footer1 {
  width: 100%;
  height: 112px;
  background: linear-gradient(180deg, #06bf6d 0%, #24d5d0 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  bottom: 0;
}
.fotterCountent {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.fotterTextStyle {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
}
</style>
