<template>
  <div>
    <van-swipe class="my-swipe" vertical :style="getpageHeight()" :loop="false">
      <van-swipe-item><d1 @updatea="changeMask"></d1></van-swipe-item>
      <van-swipe-item><d2 @updatea="changeMask"></d2></van-swipe-item>
      <van-swipe-item><d3 @updatea="changeMask"></d3></van-swipe-item>
      <van-swipe-item><d4 @updatea="changeMask"></d4></van-swipe-item>
      <van-swipe-item><d5 @updatea="changeMask"></d5></van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/4</div>
      </template>
    </van-swipe>
  </div>
  <div class="loading">
		<div class="mask" v-if="showMask">
			<div class="content">
				<p @click="goToRoute('home')">首 页&nbsp;&nbsp; 〉</p>
				<p @click="goToRoute('/abooutUs')">关于我们&nbsp;&nbsp; 〉</p>
			</div>
			<div class="title">
				<div class="titleFlex">
					<div  @click="() => $router.push('/')">荐神APP</div>
					<div  @click="showMask = false">X</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import D1 from '../components/d1'
import D2 from '../components/d2'
import D3 from '../components/d3'
import D4 from '../components/d4'
import D5 from '../components/d5'

export default {
  components: {
    D1,
    D2,
    D3,
    D4,
    D5,
  },
  data() {
    return {
      showMask : false,
    }
  },
  methods: {
    changeMask(value) {
      this.showMask = value;
    },
    goToRoute(routeName) {
        let u = this.$route.name;
        if(u==routeName){
            this.showMask = false;
        }else{
            this.$router.push(routeName);
        }
    },
    getpageHeight() {
      let height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      height = height + 'px'

      return { height: height, width: '100%' }
    },
  },
}
</script>
<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #fff;
}

.van-swipe__indicators van-swipe__indicators--vertical {
  opacity: 0;
}
.title-img{
	width: 45px
}
.mask {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
}
.content {
    position: absolute;
    top: 50%;
    left: 0;
    color: white;
    text-align: center;
    line-height: 66px;
    width: 100%;
}
.titleFlex {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  color: #06bf6d;
  font-size: 17px;
}
.title{
	text-align: right;
	padding: 14px 18px 31px 0;
}
</style>
