<template>
  <div class="index">
    <div class="title">
      <div class="titleFlex">
        荐神APP
        <img
          class="title-img"
          src="../assets/icon-unfold@2x.png"
          @click="imgClick"
        />
      </div>
    </div>
    
    <div class="text1">
      <img class="text1-img" src="../assets/text-资金管理@2x.png" />
      <div class="text1-text">荐神协作，加速推荐</div>
      <div class="text1-text">共飨面试、入职丰厚奖金。</div>
    </div>
    <!-- <div class="phone">
      <div class="new-phoneImg">
        <img src="../assets/new/资金管理.gif" alt="" class="new-phoneImgGif" />
      </div>
      <a!-- <img class="phone-img1" src="../assets/iphone@2x.png" /> --a>
      <img class="phone-img2" src="../assets/decoration-lock@2x.png" />
    </div> -->
    <div class="phone-new1">
      <img class="img-new1" src="../assets/new/资金管理-手机.gif" alt="">
    </div>
    <!-- <div class="down">
      <div class="down-text">向下滑动</div>
      <img class="down-img" src="../assets/vector-scroll@2x.png" />
    </div> -->
    <div :class="$props.changeHeader ? 'fotter' : 'footer1'">
      <div class="fotterCountent">
        <div class="fotterTextStyle" style="margin-top: 24px">
          荐神（上海）科技有限公司
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
          "
        >
          <span class="fotterTextStyle" @click="handelOpen('wh')"  style="text-decoration: underline;">
            沪ICP备2023006285号-1
          </span>
          <!-- <img src="../assets//icon-公安.png" alt="" style="margin: 0 8px" /> -->
          <!-- <span class="fotterTextStyle" @click="handelOpen(false)"> -->
            <!--沪公网安备  号-->
          <!-- </span> -->
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            src="../assets//icon-email.png"
            alt=""
            style="margin-right: 8px"
          />
          <span class="fotterTextStyle"> 邮箱地址：gavin@ituiu.com </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    imgClick() {
      // this.$router.push({
      //   name: 'abooutUs',
      // })
      this.$emit('updatea', true);
    },
    handelOpen(type) {
      if (type) {
        window.open('https://beian.miit.gov.cn/')
      } else {
        window.open('https://beian.mps.gov.cn/#/')
      }
    },
  },
}
</script>
<style scoped>
.titleFlex {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  color: #06bf6d;
  font-size: 17px;
}
.index {
  width: 100%;
  height: 100%;
  color: #000;
}

.title {
  text-align: right;
  margin: 14px 18px 24px 0;
}
.title-img {
  width: 45px;
}
.text1 {
  text-align: center;
}
.text1-img {
  width: 145px;
  height: 33px;
  margin-bottom: 15px;
}
.text1-text {
  margin-bottom: 2px;
  text-align: center;
  color: #78879c;
  font-size: 12px;
}

.phone-new1{
  width: 100%;

}
.img-new1 {
    max-width: 100%;
  }
.new-phoneImg {
  /* height: 378x; */
  padding-bottom: 36%;
  position: relative;
  background-image: url('../assets/iphone@2x.png');
  background-size: cover;
}
.new-phoneImg .new-phoneImgGif {
  margin-top: 20%;
  margin-left: -18%;
  width: 38.5%;
  /* height: 309px; */
  /* position: relative; */
}
.phone {
  /* height: 378x; */
  text-align: center;
  position: relative;
}
.phone-img1 {
  width: 100%;
}

.phone-img2 {
  width: 53px;
  height: 53px;
  position: absolute;
  bottom: 24%;
  left: 9%;
}

.down {
  text-align: center;
}

.down-text {
  color: #808ea2;
  margin-bottom: 7px;
}
.down-img {
  height: 39px;
  margin-bottom: 36px;
}
.fotterTextStyle {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
}
.fotter {
  width: 100%;
  height: 112px;
  background: linear-gradient(180deg, #06bf6d 0%, #24d5d0 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
}
.footer1 {
  width: 100%;
  height: 112px;
  background: linear-gradient(180deg, #06bf6d 0%, #24d5d0 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.fotterCountent {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>
