<template>
  <div class="index" :style="getpageHeight()">
    <video autoplay loop muted class="videohome">
      <source src="../assets/new/clip.mp4" type="video/mp4">
    </video>
    <div class="title">
      <div class="titleFlex">
        荐神APP
        <img
          class="title-img"
          src="../assets/icon-unfold@2x.png"
          @click="imgClick"
        />
      </div>
    </div>
    <div class="text">
      <img class="text-img" src="../assets/text-能者@2x.png" />
    </div>
    <div class="segmentation"></div>
    <div class="text2">有温度的传承式求职招聘平台</div>

    <div class="download">
      <!-- <div class="download-item">
        <img class="download-item-img1" src="../assets/icon-apple@2x.png" />
        <img class="download-item-img2" src="../assets/text-download@2x.png" />
        <div class="download-item-text" @click="openView">IOS 下载</div>
      </div> -->
      <div class="download-item">
        <img class="download-item-img1" src="../assets/icon-android@2x.png" />
        <img class="download-item-img2" src="../assets/text-download@2x.png" />
        <div class="download-item-text" @click="openView">APP 下载</div>
      </div>
    </div>

    <div class="down">
      <div class="down-text">向下滑动</div>
      <img class="down-img" src="../assets/vector-scroll@2x.png" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight + 'px',
    }
  },
  methods: {
    imgClick() {
      // this.$router.push({
      //   name: 'abooutUs',
      // })
      this.$emit('updatea', true);
    },
    openView() {
      window.open('https://a.app.qq.com/o/simple.jsp?pkgname=jianshen.front')
    },
    getpageHeight() {
      let height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      height = height + 'px'

      return { height: height, width: '100%' }
    },
  },
}
</script>

<style scoped>
.titleFlex {
  display: flex;
  justify-content: space-between;
  margin-left: 18px;
  font-size: 17px;
}
.index {
  width: 100%;
  /* background-image: url('../assets/bg-h5.png'); */
  background-size: cover;
}
.videohome {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    background-image: url('../assets/new/video-bg.png');
    background-size: cover;
    background-position: center center;
  }
.title {
  text-align: right;
  padding: 14px 18px 122px 0;
  position: relative;
}
.title-img {
  width: 45px;
}
.text {
  text-align: center;
  position: relative;
}
.text-img {
  width: 304px;
  height: 30px;
}

.segmentation {
  margin: 18px 145px 14px;
  text-align: center;
  height: 1px;
  background-color: #fff;
  position: relative;
}

.text2 {
  text-align: center;
  color: #fff;
  font-size: 14px;
  height: 19px;
  position: relative;
}

.download {
  padding: 63px 33px 0;
  display: flex;
  justify-content: space-between;
}
.download-item {
  position: relative;
  width: 144px;
  height: 50px;
  background-color: rgba(9, 9, 20, 0.8);
  border-color: #fff;
  border-radius: 7px;
  margin:0 auto;
}
.download-item-img1 {
  width: 21px;
  height: 21px;
  position: absolute;
  left: 14px;
  top: 14px;
}
.download-item-img2 {
  width: 87px;
  height: 6px;
  position: absolute;
  top: 15px;
  right: 14px;
}

.download-item-text {
  position: absolute;
  left: 42px;
  bottom: 11px;
  font-size: 12px;
  color: #fff;
}

.down {
  margin-top: 60%;
  text-align: center;
  position: relative;
}

.down-text {
  color: #808ea2;
  margin-bottom: 7px;
}
.down-img {
  height: 39px;
  margin-bottom: 36px;
}
</style>
